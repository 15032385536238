import { useUserStore } from "@/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (/account\/password-reset/.test(to.path)) {
    return;
  }
  const userStore = useUserStore();
  await userStore.validate();
  if (!userStore.logged) {
    return navigateTo(`/${to.params.country}/${to.params.locale}/signin`, {
      redirectCode: 301,
    });
  }
});
